import React, { useState } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import Logo from './Logo';
import Icon from './Icon';
import bannerImageOne from '../images/banner-image-1.jpg';
import bannerImageTwo from '../images/banner-image-2.jpg';
import bannerImageThree from '../images/banner-image-3.jpg';
import bannerImageFour from '../images/banner-image-4.jpg';
import bannerImageFive from '../images/banner-image-5.jpg';
import bannerImageSix from '../images/banner-image-6.jpg';

const images = [
  bannerImageOne,
  bannerImageTwo,
  bannerImageThree,
  bannerImageFour,
  bannerImageFive,
  bannerImageSix,
];

const AwesomeAutoplaySlider = withAutoplay(AwesomeSlider);

const SliderItemContent = ({ index }) => (
  <div>
    <h1>HELLO</h1>
  </div>
);

const BannerSlider = ({ className = '' }) => {
  // const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <section className={className}>
      <AwesomeAutoplaySlider
        className="slider"
        buttons
        bullets
        cancelOnInteraction
        fillParent
        infinite
        organicArrows
        play
        // onTransitionEnd={({ currentIndex }) => setCurrentIndex(currentIndex)}
        showTimer={false}
      >
        <div className="slider-content main" data-src={images[0]}>
          <Logo width={200} />
          <Fade bottom duration={2000} distance="20%">
            <h1>making trust tangible.</h1>
          </Fade>
          <div className="column-divider" />
        </div>
        <div className="slider-content other" data-src={images[1]}>
          <div className="banner-overlay __1" />
          <Fade right duration={2500} distance="10%">
            <div className="body">
              <Icon width={100} />
              <p>
                Too scared to buy that item from that Facebook or Twitter page? Don't fret, <span className="bold">just foongit</span>.
                  </p>
              <p>
                Using <span className="bold">foongitPay</span> ensures that your money is safe till you receive your item
                    and are 100% happy with it.
                  </p>
            </div>
            <div className="column-divider" />
          </Fade>
        </div>
        <div className="slider-content other" data-src={images[2]}>
          <div className="banner-overlay __2" />
          <div className="column-divider" />
          <Fade right duration={2500} distance="10%">
            <div className="body">
              <Icon width={100} />
              <p>
                It's never been easier to protect yourself and your pocket from unscrupulous online vendors.
              </p>
              <p>
                Incorporating <span className="bold">foongit</span> prevents you from being scammed. Your money stays
                secure in the vault until your items are received.
              </p>
              <p className="bold">
                just foongit
              </p>
            </div>
          </Fade>
        </div>
        <div className="slider-content other" data-src={images[3]}>
          <div className="banner-overlay __3" />
          <Fade right duration={2500} distance="10%">
            <div className="body">
              <Icon width={100} />
              <p>
                Build trust and a solid customer base with <span className="bold">foongit</span>. Trust is an incredibly
                important asset, ensure it by incorporating <span className="bold">foongit</span> into your payment
                process. Your customers will love you for it.
              </p>
              <p className="bold">
                just foongit
              </p>
            </div>
            <div className="column-divider" />
          </Fade>
        </div>
        <div className="slider-content other" data-src={images[4]}>
          <div className="banner-overlay __4" />
          <Fade right duration={2500} distance="10%">
            <div className="column-divider" />
            <div className="body">
              <Icon width={100} />
              <p>
                Wondering how many potential clients have decided not to purchase from you because of <span className="bold">trust</span>? Wonder no longer, with foongitPay you can trust proof your
                transactions and secure a place in the hearts and minds of customers.
              </p>
              <p>
                We get it, we're in the business of <span className="italics">making trust tangible</span>.
              </p>
              <p className="bold">
                just foongit
              </p>
            </div>
          </Fade>
        </div>
        <div className="slider-content other" data-src={images[5]}>
          <div className="banner-overlay __5" />
          <Fade right duration={2500} distance="10%">
            <div className="body">
              <Icon width={100} />
              <p>
                Online purchase scam survivor? Protect your hard earned moola the next time by asking
                for <span className="bold">foongitPay</span>.  Your wallet will thank you for it.
              </p>
              <p className="bold">
                just foongit
              </p>
            </div>
            <div className="column-divider" />
          </Fade>
        </div>
      </AwesomeAutoplaySlider>
    </section>
  );
};

export default styled(BannerSlider)`
  --image-height: calc(100vh - var(--header-height) * 1.5);
  color: var(--color-white);

  width: 100vw;
  display: flex;
  height: var(--image-height);

  .banner-overlay {
    position: absolute;
    height: calc(100% - var(--header-height));
    opacity: 0.38;
    top: var(--header-height);
    left: 0;
    right: 0;

    &.__1 { background: var(--color-primary); }
    &.__2 { background: #005aff; }
    &.__3 { background: #565cf3; }
    &.__4 { background :#00ddf9; }
    &.__5 { background: #3e7ff5; }
  }

  p, span {
    font-family: 'AvenirLight';

    &.bold {
      font-family: 'AvenirBold';
    }
    &.italics {
      text-decoration: italics;
    }
  }

  .slider-content {
    flex: 1;
    display: grid;

    &.main {
      grid-template-rows: repeat(3, 1fr);
      align-items: center;
      justify-items: center;
      grid-gap: 50px;

      h1 {
        font-size: 4rem;
      }
    }

    &.other {
      grid-template-columns: 1fr 1fr;
      margin: 0 var(--control-button-width);
      font-size: 1.6rem;

      :last-child {
        justify-self: flex-end;
      }

      > div.body {
        display: grid;
        grid-gap: 20px;
        width: 466px;

        img {
          justify-self: center;
          align-self: flex-end;
        }
      }
    }
  }

  nav.awssld__bullets {
    --control-bullet-active-color: transparent;
    --control-bullet-color: var(--color-white);
    bottom: 30px;
    z-index: 2;

    > button {
      border: 1px solid var(--color-white);
      height: 6px;
      width: 6px;
    }
  }

  div.awssld__controls {
    --organic-arrow-color: var(--color-white);
  }

  @media only screen and (max-width: 768px) {
    .banner-overlay {
    }
    .slider-content {
      &.main, &.other {
        width: 100%;
        font-size: 1.3rem;
        grid-template-columns: unset;
        text-align: center;

        h1 {
          font-size: 2.5rem;
        }

        > div.body {
          grid-gap: 0;
          width: 100%;

          img {
            display: none;
          }
        }
      }
    }
  }
`;
