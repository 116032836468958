import React from 'react';
import styled from 'styled-components';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

import screenshotIphone12one from '../images/screenshot-iphone12-one.png';
import screenshotIphone12two from '../images/screenshot-iphone12-two.png';
import screenshotIphone12three from '../images/screenshot-iphone12-three.png';

const AwesomeAutoplaySlider = withAutoplay(AwesomeSlider);

const DevicePreview = ({ className = '' }) => {

  return (
    <div className={className}>
      <div className="device-wrapper">
        <div className="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
          <div className="screen">
            <AwesomeAutoplaySlider
              className="slider"
              buttons={false}
              bullets={false}
              cancelOnInteraction={false}
              fillParent
              infinite
              media={[
                { source: screenshotIphone12one },
                { source: screenshotIphone12two },
                { source: screenshotIphone12three },
              ]}
              organicArrows={false}
              play
              showTimer={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default styled(DevicePreview)`
  .device-wrapper {
    width: var(--device-width);

    .slider {
      z-index: 0;
    }
  }
`;
