import React from 'react';
import styled from 'styled-components';
import { Button, Form, Input, TextArea } from 'semantic-ui-react';
import { OutboundLink } from 'gatsby-plugin-gtag';

import AppleStoreBadge from '!svg-react-loader!../images/apple-store-badge.svg?name=AppleStoreBadge';
import huaweiBannerImageBlack from '../images/huawei-badge-black.png';
import Layout from '../components/layout';
import SEO from '../components/seo';
import DevicePreview from '../components/DevicePreview';

import '../theme/theme.css';
import BannerSlider from '../components/BannerSlider';

const IndexPage = ({ className }) => (
  <Layout className={className}>
    <SEO title="Home" />
    <BannerSlider />
    <section id="about" className="section-1">
      <p>Our vision is to become a digital transactional platform that makes trust tangible.</p>
      {/* <Button basic>Services</Button> */}
    </section>
    <section className="section-2">
      <div />
      <div>
        <img src="https://static.wixstatic.com/media/729341_5bb74ccdf5cf4cf9bb7eae199966e4ab~mv2.jpg/v1/crop/x_0,y_146,w_5472,h_3357/fill/w_776,h_476,al_c,q_85,usm_0.66_1.00_0.01/pexels-negative-space-34577bw.webp" />
      </div>
      <div />
      <div>
        <h3>About foongit</h3>
        <p>
          foongit is about revolutionizing payment for services and goods. Never worry about being scammed again.
          Through the foongit app, money is stored safely within a secure vault and only released once you’ve received
          the ordered goods or services. Payments will never be the same again. Go ahead, foongit.
        </p>
        {/* <Button basic inverted>Learn More</Button> */}
      </div>
    </section>
    <section id="download" className="section-3">
      <div className="content">
        <DevicePreview />
        <div className="download-details">
          <h1>just foongit.</h1>
          <p>Download the App today</p>
          <div className="app-store-links">
            <OutboundLink href='https://play.google.com/store/apps/details?id=com.foongit'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' /></OutboundLink>
            <OutboundLink className="badge-link" href="https://apps.apple.com/za/app/foongit/id1554161305">
              <AppleStoreBadge />
            </OutboundLink>
            <OutboundLink href='https://appgallery.huawei.com/app/C104316427' className="huawei"><img alt='Get it on Huawei App Gallery' src={huaweiBannerImageBlack} /></OutboundLink>
          </div>
        </div>
      </div>
    </section>
    <section id="contact" className="contact">
      <div />
      <div>
        <img src="https://static.wixstatic.com/media/729341_4b46c8e40e6b419f97c3b7acb73e8e77~mv2.jpg/v1/fill/w_917,h_1005,al_c,q_85,usm_0.66_1.00_0.01/729341_4b46c8e40e6b419f97c3b7acb73e8e77~mv2.webp" />
      </div>
      <div />
      <div />
      <div>
        <h3>Contact us</h3>
        <Form data-netlify="true" className="form" name="contact" method="POST" action="/contact-success">
          <input type="hidden" name="form-name" value="contact" />
          <Input
            name="name"
            placeholder="First Name"
            type="text"
            requred
          />
          <Input
            name="lastName"
            placeholder="Last Name"
            type="text"
            required
          />
          <Input
            name="phoneNumber"
            placeholder="Phone Number"
            type="tel"
          />
          <Input
            name="email"
            placeholder="Email"
            type="email"
            required
          />
          <TextArea
            name="message"
            inverted
            placeholder="Leave us a message"
            required
          />
          <Button
            basic
            inverted
            type="submit"
          >
            Submit
          </Button>
        </Form>
      </div>
    </section>
  </Layout>
);

export default styled(IndexPage)`
  .layout {
    .section-1 {
      height: 20rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 2rem;
      text-align: center;
      line-height: 1;
      margin: 50px 0;
      width: 100vw;

      p {
        width: 50vw;

        @media only screen and (max-width: 768px) {
          width: 80vw;
        }
      }

    }

    .section-2 {
      height: 100vh;
      display: grid;
      width: 100vw;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(14, 1fr);

      > :nth-child(1) {
        background: var(--color-accent);
        grid-row-start: 1;
        grid-row-end: 9;
        grid-column-start: 2;
        grid-column-end: 6;
      }

      > :nth-child(2) {
        grid-row-start: 2;
        grid-row-end: 8;
        grid-column-start: 3;
        grid-column-end: 7;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      > :nth-child(3) {
        display: none;
        background-color: var(--color-dark-grey);
        grid-row-start: 5;
        grid-row-end: 7;
        grid-column-start: 5;
        grid-column-end: 8;
      }

      > :nth-child(4) {
        display: grid;
        grid-template-rows: 1fr auto 1fr;
        align-content: center;
        color: var(--color-white);
        background-color: var(--color-dark-purple);
        padding: 10px;
        text-align: center;

        grid-row-start: 5;
        grid-row-end: 11;
        grid-column-start: 5;
        grid-column-end: 8;

        h3 {
          color: var(--color-accent);
          font-size: 2rem;
          margin-top: 20px;
          margin-left: auto;
          margin-right: auto;
          width: 50%;
        }
        p {
          flex: 1;
          padding: 0 36px;
          font-size: 0.9rem;
        }
        button {
          align-self: center;
        }
      }

      @media only screen and (max-width: 768px) {
        > :nth-child(1), > :nth-child(2), > :nth-child(3), > :nth-child(4) {
          grid-column-start: 1;
          grid-column-end: 9;
        }
        > :nth-child(2) {
          grid-column-start: 2;
          grid-column-end: 8;
          grid-row-end: 7;
          z-index: 1;
        }
        > :nth-child(3) {
          display: unset;
        }
        > :nth-child(4) {
          grid-row-start: 7;
          grid-row-end: 14;

          h3 {
            width: unset;
          }
          p {
            padding: unset;
          }
        }
      }
    }

    .section-3 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 50px 20px;
      padding-bottom: 100px;
      width: 100vw;
      background: var(--color-white);

      > .content {
        display: grid;
        grid-gap: 100px;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: center;

        .download-details {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 1rem;
          text-align: center;

          h1 {
            font-size: 3rem;
          }

          .app-store-links {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              height: 4rem;
            }
            svg {
              width: 8rem;
              height: 3rem;
            }
            .huawei img {
              height: 2.8rem;
              margin-left: 12px;
            }
          }
        }
      }
      @media only screen and (max-width: 768px) {
        padding-bottom: 50px;
        > .content {
          grid-gap: 20px;
          .download-details .app-store-links {
            img {
              height: 2rem;
            }
            svg {
              width: 4rem;
              height: 2rem;
            }
            .huawei img {
              margin-left: 4px;
              height: 1.4rem;
            }
          }
        }
      }
    }

    .contact {
      display: grid;
      height: 100vh;
      width: 100vw;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(14, 1fr);

      > :nth-child(1) {
        background-color: var(--color-white);
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 2;

        @media only screen and (max-width: 768px) {
          display: none;
        }
      }

      > :nth-child(2) {
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        margin-top: -100px;

        img {
          position: sticky;
          top: 0;
          width: 70vw;
          height: 70vh;
          object-fit: cover;
          object-position: top;
        }

        @media only screen and (max-width: 768px) {
          grid-column-start: 1;
          grid-column-end: 9;
          grid-row-start: 1;
          grid-row-end: 5;
          position: unset;
          height: unset;
          margin-top: unset;

          img {
            content: url("https://static.wixstatic.com/media/729341_4b46c8e40e6b419f97c3b7acb73e8e77~mv2.jpg/v1/fill/w_640,h_400,al_c,q_80,usm_0.66_1.00_0.01/729341_4b46c8e40e6b419f97c3b7acb73e8e77~mv2.webp");
            position: unset;
            height: 100%;
            width: 100%;
          }
        }
      }

      > :nth-child(3) {
        background-color: var(--color-white);
        grid-column-start: 7;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 15;

        @media only screen and (max-width: 768px) {
          display: none;
        }
      }

      > :nth-child(4) {
        background-color: var(--color-white);
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 10;
        grid-row-end: 15;

        @media only screen and (max-width: 768px) {
          display: none;
        }
      }

      > :nth-child(5) {
        align-content: center;
        color: var(--color-white);
        background-color: var(--color-dark-purple);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 40px;
        text-align: center;

        grid-row-start: 4;
        grid-row-end: 11;
        grid-column-start: 5;
        grid-column-end: 8;

        @media only screen and (max-width: 768px) {
          grid-column-start: 2;
          grid-column-end: 8;
          grid-row-start: 4;
          grid-row-end: 14;
          padding: 0 10px;
        }

        h3 {
          color: var(--color-accent);
          font-size: 2rem;
          margin-top: 20px;
          margin-left: auto;
          margin-right: auto;
          width: 70%;
        }

        form {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 20px;

          @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr;
          }

          input, textarea {
            color: var(--color-soft-white);
            background: transparent;
            border-bottom-color: var(--color-soft-white);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &::placeholder {
              color: var(--color-tertiary);
            }
            &:focus {
              background: transparent;
              color: var(--color-tertiary);
            }
          }

          button {
            align-self: flex-end;
          }
        }
      }
    }
  }
`;
